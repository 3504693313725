const masterMenu = [
  {
    title: "Master",
    // icon: require("@/assets/images/icons/User.svg"),
    // iconActive: require("@/assets/images/icons/User-Solid.svg"),
    route: "master-toko.index",
    icon: "LockIcon",
  },
];

export default masterMenu;
